import { Slide, toast } from "react-toastify";
import parse from "html-react-parser";

const sToast = (msg) => {
  toast.success(msg, {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  });
};

const eToast = (msg) => {
  toast.error(msg, {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  });
};

const MoneyFormatter = (value) => {
  if (value == null) return "N/A";

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};
const HTMLParser = (htmlString) => {
  return parse(htmlString);
};

const Utility = {
  sToast,
  eToast,
  HTMLParser,
  MoneyFormatter,
};

export default Utility;
