import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import "./ConfirmMessage.css";
function ConfirmMessage(props) {
  return (
    <Row className="justify-content-center mt-5" id="messageModal">
      <Col md={6} className="mb-2">
        <Toast
          show={props.valid}
          className="w-100"
          style={{ background: "#FEFAE4" }}
        >
          <Toast.Header style={{ background: "#C99F62" }}>
            <strong className="text-color mx-auto pt-1 pb-1">
              Congratulations! Your Submission Was Successful!
            </strong>
          </Toast.Header>
          <Toast.Body className="m-5 confirmToastBody">
            <div className="p-3 ContentDiv">
              <p className="text-color">
                Thank you for submitting your information. Your details have
                been successfully received, and we'll pro lorem20hgvshgqfstqfsfqufquff
              </p>
              <p className="text-color mt-3">
                Should you have any questions or require further assistance,
                please don't hesitate to contact us. We're here to help!
              </p>
            </div>
          </Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}

export default ConfirmMessage;
