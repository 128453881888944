import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import background from "../../Assets/UserProduct/background.png";
import { data } from "./constants/BrowseCategoryData";
import mainframe from "../../Assets/UserProduct/mainframe.png";
import "./Landing.css";
import ProductShopNow from "../../Globalcomponents/ShopNowButton/ProductShopNow";
import { TbMessageMinus } from "react-icons/tb";
import img1 from "../../Assets/Productimg/img1.png";
import { Footer } from "../../Globalcomponents/footer/MainFooter";
import { useNavigate } from "react-router-dom";
import messageIcon from "../../Assets/UserProduct/messageIcon.png";
import ContactusModal from "../Modals/ContactusModal/ContactusModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Utility from "../../Utils/Utility";
import { UserController } from "../../Redux/controllers/UserController";
import axios from "axios";

const LandingPage = () => {
  const [allCategory, setAllCategory] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state?.user);
  const [allProducts, setAllProducts] = useState([]);

  const moveDown = () => {
    const browseSection = document.getElementById("browse");
    if (browseSection) {
      browseSection.scrollIntoView({ behavior: "smooth" });
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fetchAllCategory = async () => {
    try {
      const response = await UserController.getAllCategory(token);
      setAllCategory(response.data.data);
    } catch (error) { }
  };

  useEffect(() => {
    getAllProducts();
    fetchAllCategory();
  }, []);

  const getAllProducts = () => {
    UserController.getProducts(token)
      .then((res) => {
        setAllProducts(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showContactModal, setShowContactModal] = useState(false);
  const handleContactUs = () => {
    setShowContactModal(true);
  };
  const [dataByCategory, setDataByCategory] = useState(["default"]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handelClick = async (item, index) => {
    setSelectedCategory(index);
    try {
      const res = await UserController.getProductByCategory(item?._id);
      console.log("resp", res);
      setDataByCategory(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Container id="landingDiv">
      {/* MODAL  */}
      {showContactModal && (
        <ContactusModal
          show={showContactModal}
          onHide={() => {
            setShowContactModal(false);
          }}
        />
      )}
      <div id="msgBtn" onClick={handleContactUs}>
        <TbMessageMinus className="MessageIconContainer" />
      </div>
      <Row className="mt-4">
        <Col className="browse">
          <p>Browse by Categories</p>
        </Col>
      </Row>
      <Row className="row2">
        {allCategory?.map((item, index) => (
          <Col md={2} xs={6} key={index}>
            <div
              className="maincontainer"
              onClick={() => {
                handelClick(item, index);
                moveDown();
              }}
            >
              <div
                className="background"
                style={{
                  backgroundColor:
                    selectedCategory === index
                      ? "rgba(103, 188, 103, 0.424)"
                      : "rgba(244, 244, 242, 1)",
                }}
              ></div>
              <div className="subcontainer">
                <div>
                  <img src={item.image} alt="" className="category-image" />
                </div>
                <div className="containt">
                  <div className="con">
                    <p>{item.name}</p>
                    <p>{item.product_available}</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <Row>
        <div class="line"></div>
      </Row>
      {/* <div className="frame">
        <div className="frameimage">
          <img className="frameimage" src={mainframe} alt="frame Image" />
        </div>
        <div className="frametext">
          <h3 className="">New lower price</h3>
          <p>
            We've just reduced the prices of our favorite products, making them
            even more affordable. Take advantage of these lowered prices and
            discover a wide range of options.
          </p>
        </div>
      </div> */}
      {/* <Row>
        <div class="line"></div>
      </Row> */}
      <Row className="mt-3">
        <Col className="browse">
          {dataByCategory[0] === "default" ? (
            <p className="choosecategory">All Products</p>
          ) : dataByCategory.length === 0 ? (
            <p>No product Available for this category</p>
          ) : (
            <p>{dataByCategory[0]?.category?.name}</p>
          )}
        </Col>
      </Row>
      <div className="landingLstRow">
        {dataByCategory[0] !== "default" ? (
          dataByCategory?.map((item, index) => (
            <div className="productcontainer" key={index}>
              <div className="productimage">
                <img className="image" src={item?.images[0] || img1} />
              </div>

              <div className="productname">{item.name ? item.name : <></>}</div>

              <div className="product-details">
                <div className="productprice">
                  {item?.price ? Utility.MoneyFormatter(item?.price) : <></>}
                </div>

                <div onClick={() => navigate(`/product-details/${item._id}`)}>
                  <ProductShopNow />
                </div>
              </div>
            </div>
          ))
        ) : dataByCategory.length === 0 ? (
          <p> No Products found for this category</p>
        ) : (
          allProducts?.map((item, index) => (
            <div className="productcontainer" key={index}>

              <div className="productimage">
                <img className="image" src={item?.images[0] || img1} />
              </div>

              <div className="productname">{item.name ? item.name : <></>}</div>

              <div className="product-details">
                <div className="productprice">
                  {item?.price ? Utility.MoneyFormatter(item?.price) : <></>}
                </div>

                <div onClick={() => navigate(`/product-details/${item._id}`)}>
                  <ProductShopNow />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <Row>
        <div class="line"></div>
      </Row>
      <div className="frame">
        <div className="frameimage">
          <img className="frameimage" src={mainframe} alt="frame Image" />
        </div>
        <div className="frametext">
          <h3 className="">New lower price</h3>
          <p>
            We've just reduced the prices of our favorite products, making them
            even more affordable. Take advantage of these lowered prices and
            discover a wide range of options.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default LandingPage;
