import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import "./DashboardModal.css";
import { FaDownload } from "react-icons/fa";
import ProgressBar from "../../../Globalcomponents/ProgressBar/PrograssBar";
import Utility from "./../../../Utils/Utility";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function DashboardModal(props) {
  const alldata = props.product;
  const { token } = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(false);

  const handleFileDownload = async () => {
    const id = alldata?._id;
    try {
      setLoading(true);
      const resp = await UserController.downloadYourInvoice(id, token);
      console.log("Responce", resp.data);
      const cloudinaryUrl = resp.data.data;
      if (cloudinaryUrl) {
        const newWindow = window.open(cloudinaryUrl, "_blank");
        if (
          !newWindow ||
          newWindow.closed ||
          typeof newWindow.closed == "undefined"
        ) {
          alert("Popup blocked. Please allow popups for this website.");
          setLoading(false);
        }
        setLoading(false);
      } else {
        console.log("No URL found in response data");
        setLoading(false);
      }
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  console.log("All Data", alldata);
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      className="text-color"
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      id="dashboardModal"
    >
      <Modal.Header
        closeButton
        id="modalheadercontaint"
        style={{ backgroundColor: "#F4F4f2", padding: "10px" }}
      >
        <Modal.Title id="example-modal-sizes-title-lg">
          Order Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="imageRow">
          {alldata?.productDetails?.map((product) => (
            <Row sm={6} className="item">
              <Col sm={6}>
                <img
                  onClick={() =>
                    navigate(`/product-details/${product?.product?._id}`)
                  }
                  id="modalImg"
                  alt="image"
                  src={
                    product?.product?.images[0] ||
                    product?.product?.images[1] ||
                    product?.product?.images[2] ||
                    product?.product?.images[3]
                  }
                />
              </Col>
              <Col sm={6} className="productDescription">
                <h6>Name: {product?.product?.name}</h6>
                <h6>ID: {product?.product?.productID}</h6>
                <div className="font-color">
                  <p>
                    <h6>Description :</h6>
                    <i> {Utility.HTMLParser(product?.product?.description)}</i>
                  </p>
                </div>
              </Col>
            </Row>
          ))}
        </Row>
        <Row className="orderInfo">
          <div className="downloadbutton">
            <div className="orderInfoDiv">
              <h6>Order ID - {alldata?.orderID}</h6>
              <h6>
                Total Amount :{" "}
                {Utility.MoneyFormatter(alldata?.paymentDetails?.totalAmount)}
              </h6>
              <p>
                Shipping Address: {alldata?.customerDetails?.shippingAddress}
              </p>
              {loading ? (
                <div
                  style={{ marginLeft: "45%" }}
                  className="spinner-border loader"
                  role="status"
                >
                  <span class="sr-only"></span>
                </div>
              ) : (
                <p
                  style={{ backgroundColor: "#dc2329", color: "#fff" }}
                  className="text-color mb-1 mt-1"
                  id="downloadText"
                  onClick={handleFileDownload}
                >
                  Download your invoice
                  <span id="downloadIcon" className="mb-0">
                    <FaDownload></FaDownload>
                  </span>
                </p>
              )}
            </div>
          </div>
          <Col className="progressModal">
            <ProgressBar ProgressData={alldata} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardModal;
