import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const ProgressBar = (props) => {
  const steps = ["pending", "confirmed", "processing", "shipped", "delivered"];
  const [activeStep, setActiveStep] = useState(0);

  const ProgressData = props.ProgressData;
  const DeliveryStatus = ProgressData?.deliveryStatus;

  useEffect(() => {
    if (DeliveryStatus) {
      const stepIndex = steps.indexOf(DeliveryStatus);
      if (stepIndex !== -1) {
        setActiveStep(stepIndex);
      } else {
        setActiveStep(0);
      }
    }
  }, [DeliveryStatus, steps]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={index <= activeStep}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressBar;
