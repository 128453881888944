import React from "react";
import Spinner from "react-bootstrap/Spinner";

function Loader(props) {
  return (
    <div className="mx-1">
      <Spinner
        animation={props.animation ? props.animation : "border"}
        variant={props.variant ? props.variant : "danger"}
        size={props.size ? props.size : "lg"}
      />
    </div>
  );
}

export default Loader;
