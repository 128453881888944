import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Container } from "react-bootstrap";
import "./ContactusModal.css";
import ConfirmMessage from "../ConfirmMessage/ConfirmMessage";
import { useState } from "react";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../Utils/Utility";
import { useSelector } from "react-redux";

export default function ContactusModal(props) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { userInfo } = useSelector((st) => st?.user);

  const [input, setInput] = useState({
    name: userInfo?.name ? userInfo?.name : "",
    email: userInfo?.email ? userInfo?.email : "",
    phoneNumber: userInfo?.phoneNo ? userInfo?.phoneNo : "",
    interestedProduct: "",
    message: "",
  });

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    setShowConfirmation(true);
    console.log("here alll data ======>", input);
    if (!input.name) {
      Utility.eToast("Enter your Name.");
      return;
    }
    if (!input.email || !regex.test(input.email)) {
      Utility.eToast("Enter your valid Email Address.");
      return;
    }
    if (!input.phoneNumber) {
      Utility.eToast("Enter your Phone Number.");
      return;
    } else if (input.phoneNumber.length !== 10) {
      Utility.eToast("Mobile Number should be 10 digits.");
      return;
    }
    if (!input.interestedProduct) {
      Utility.eToast("Enter your Interested Product.");
      return;
    }
    if (!input.message) {
      Utility.eToast("Enter your message.");
      return;
    } else if (input.message.length < 3) {
      Utility.eToast("Message should be minimum 2 letters .");
      return;
    }
    try {
      const response = await UserController.AddQuery(input);
      Utility.sToast(
        "Query submitted successfully, you will be contacted soon"
      );
      console.log(response);
      props.onHide();
    } catch (error) {
      console.log(error);
      Utility.eToast("Something went wrong");
    }
  };

  return (
    <div className="rounded-4">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="contactModal"
      >
        <Modal.Header closeButton style={{ background: "#F4F4F2" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modalHeading"
          >
            Please provide your contact information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="mx-auto d-flex justify-content-center align-items-center flex-column"
          id="backGround"
        >
          <Form className="w-100">
            <Form.Group className="mb-4 mt-3">
              <Form.Control
                disabled={userInfo?.name || false}
                className="custom-placeholder modalInput"
                placeholder="Enter your Name"
                name="name"
                onChange={handleChange}
                value={input?.name}
                style={{ backgroundColor: "#F4F4F2", color: "black" }}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-4 mt-3">
              <Form.Control
                disabled={userInfo?.email || false}
                className="custom-placeholder modalInput"
                placeholder="Enter your Email address"
                name="email"
                onChange={handleChange}
                value={input?.email}
                style={{ backgroundColor: "#F4F4F2", color: "black" }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-4 mt-3">
              <Form.Control
                disabled={userInfo?.phoneNo || false}
                type="number"
                className="custom-placeholder modalInput"
                placeholder="Enter your Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                value={input?.phoneNumber}
                style={{ backgroundColor: "#F4F4F2", color: "black" }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-4 mt-3">
              <Form.Control
                className="custom-placeholder modalInput"
                placeholder="Enter Interested Product"
                name="interestedProduct"
                onChange={handleChange}
                style={{ backgroundColor: "#F4F4F2", color: "black" }}
              ></Form.Control>
            </Form.Group>

            <Form.Group
              className="mb-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                className="custom-placeholder modalInput"
                as="textarea"
                rows={5}
                placeholder="Enter your message"
                name="message"
                onChange={handleChange}
                style={{ backgroundColor: "#F4F4F2", color: "black" }}
              />
            </Form.Group>
            <div className="d-grid gap-2 mb-1 mt-5">
              <Button
                id="contactButton"
                size="lg"
                variant="secondary"
                onClick={handleSubmit}
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#dc2329", color: "#fff" }}
              >
                Send Message
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
