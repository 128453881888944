// import React from "react";
// import { Container, Modal } from "react-bootstrap";

// const SuccessModal = (props) => {
//   return (
//     <Modal {...props} size="lg" show={props.show}>
//       <Modal.Header closeButton style={{ background: "#C99F62" }}>
//         <Modal.Title
//           id="contained-modal-title-vcenter"
//           style={{ color: "#642315e5" }}
//           className="dashboardModalHeading"
//         >
//           Congratulations! Your Submission Was Successful!
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container>
//           Thank you for submitting your information. Your details have been
//           successfully received, and we'll process your request shortly. Should
//           you have any questions or require further assistance, please don't
//           hesitate to contact us. We're here to help!
//         </Container>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default SuccessModal;

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col } from "react-bootstrap";
import "./SuccessModal.css";

function SuccessModal(props) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal {...props} dialogClassName="custom-modal">
          <Modal.Header closeButton className="modalheaderm">
            <Modal.Title>
              Congratulations! Your Submission Was Successful!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row className="cutomizedrow">
                <Col className="mainmodalcol">
                  <div>
                    <p className="modalparagraph">
                      Thank you for submitting your information. Your details
                      have been
                    </p>
                    <p className="modalparagraph">
                      successfully received, and we'll process your request
                      shortly.
                    </p>
                    <br />
                    <p className="modalparagraphhh">
                      Should you have any questions or require further
                      assistance,
                    </p>
                    <p className="modalparagraph">
                      please don't hesitate to contact us. We're here to help!
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default SuccessModal;

// import React from "react";
// import { Modal, Container } from "react-bootstrap";
// const SuccessModal = () => {
//   return (
//     <Modal>
//       <Modal.Header closeButton style={{ background: "#C99F62" }}>
//         <Modal.Title
//           id="contained-modal-title-vcenter"
//           style={{ color: "#642315e5" }}
//           className="dashboardModalHeading"
//         >
//           Congratulations! Your Submission Was Successful!
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container>
//           Thank you for submitting your information. Your details have been
//           successfully received, and we'll process your request shortly. Should
//           you have any questions or require further assistance, please don't
//           hesitate to contact us. We're here to help!
//         </Container>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default SuccessModal;
