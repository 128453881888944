import React, { useEffect, useState } from "react";
import "./DreamComfort.css";
import { Container, Row, Col } from "react-bootstrap";
import bed from "../../Assets/DreamComfortimg/bed.png";
import ProductShopNow from "../../Globalcomponents/ShopNowButton/ProductShopNow";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CustomizedOrderModal from "../Modals/CustomizedOrderModal/CustomizedOrderModal";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { productdata } from "./constants/ProductData";
import SuccessModal from "../Modals/SuccessModal/SuccessModal";
import Utility from "../../Utils/Utility";
import { UserController } from "../../Redux/controllers/UserController";
import Loader from "../../Globalcomponents/Loader/Loader";

const DreamComfirt = () => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedPattern, setSelectedPattern] = useState(null);
  const [AdditionalInfo, setAdditionalInfo] = useState("");
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [navigateToOrder, setNavigateToOrder] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [AllAllinput, setAllAllinput] = useState({
    selectedSize: "",
    selectedColor: "",
    selectedPattern: "",
    additionalInfo: "",
    requiredQuantity: "",
    productPrice: 0,
  });

  const location = useLocation();
  const { state } = location;

  const { id } = useParams();

  const getAllProduct = async () => {
    setLoading(true);
    try {
      const response = await UserController.getProductCategoryById(id);
      console.log(response.data.data);
      setAllProducts(response.data.data);
      setAllAllinput((prev) => ({
        ...prev,
        productPrice: response?.data?.data?.price,
      }));
      console.log("initial data ", response?.data?.data?.price);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const product_Id = allProducts._id;

  const handleOrderNow = async () => {
    if (!selectedSize && !selectedColor && !selectedPattern) {
      Utility.eToast("Please select all fields before ordering");
    } else if (!selectedSize) {
      Utility.eToast("Please select Size ");
    } else if (!selectedColor) {
      Utility.eToast("Please select Color ");
    } else if (!selectedPattern) {
      Utility.eToast("Please select Pattern ");
    } else if (requiredQuantity < 1) {
      Utility.eToast("Please enter valid Number");
    }
    // else if (!AdditionalInfo) {
    //   Utility.eToast("Please provide some additional information ");
    // }
    else {
      setAllAllinput({
        productDetails: {
          product: product_Id,
          requirements: {
            selectedSize: selectedSize,
            selectedColor: selectedColor,
            selectedPattern: selectedPattern,
            additionalInfo: AdditionalInfo,
            requiredQuantity: requiredQuantity,
            productPrice: allProducts?.price,
          },
        },
      });
      setShowOrderModal(true);
    }
  };

  // console.log("AllProduct", AllAllinput);

  const isHomePage = location.pathname === "/";
  const isProductPage = location.pathname === "/product";
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const [showOrderModal, setShowOrderModal] = useState(false);

  const CustomSeparator = () => <span>&gt;</span>;

  const handleHome = () => {
    navigate("/");
  };

  const handleProduct = () => {
    navigate("/product");
  };

  const handleCloseOrderModal = () => setShowOrderModal(false);

  const handleColorSelect = (color) => {
    setSelectedColor((prevColor) => (prevColor === color ? null : color));
  };

  const handleSizeSelect = (size) => {
    setSelectedSize((prevSize) => (prevSize === size ? null : size));
  };

  const handlePatternSelect = (pattern) => {
    setSelectedPattern((prevPattern) =>
      prevPattern === pattern ? null : pattern
    );
  };

  const handletextchange = (e) => {
    setAdditionalInfo(e.target.value);
  };
  const handleQuantityChange = (e) => {
    setRequiredQuantity(e.target.value);
  };

  useEffect(() => {
    getAllProduct();
    console.log("allProducts updated:", allProducts);
    console.log("the productid is is to be passed", product_Id);
  }, []);
  console.log("Image url", allProducts.images);
  return (
    <Container className="dreamtopcomponent">
      {showOrderModal && (
        <CustomizedOrderModal
          show={showOrderModal}
          onHide={() => setShowOrderModal(false)}
          alldata={AllAllinput}
        />
      )}

      <Row>
        <Breadcrumb className="dreambredcrumb">
          <Breadcrumb.Item
            active={location.pathname === "/"}
            onClick={handleHome}
          >
            Home
          </Breadcrumb.Item>
          <CustomSeparator />
          <Breadcrumb.Item
            active={location.pathname === "/product"}
            onClick={handleProduct}
          >
            Products
          </Breadcrumb.Item>
          <CustomSeparator />
          <Breadcrumb.Item active>{allProducts?.name}</Breadcrumb.Item>
          <CustomSeparator />
        </Breadcrumb>
      </Row>

      <Row className="dreamtoprow">
        <Col sm={7} md={7}>
          {!loading ? (
            <Carousel indicators={true}>
              {allProducts?.images?.map(
                (image, index) =>
                  image.length > 5 && (
                    <Carousel.Item key={index}>
                      <div className="carousel-image-container">
                        <img src={image} alt="" className="carousel-image" />
                      </div>
                    </Carousel.Item>
                  )
              )}
            </Carousel>
          ) : (
            <div className="Loader">
              <Loader animation="border" variant="primary" size="md" />
            </div>
          )}

          <div>
            <p className="browse">{allProducts?.name}</p>
            {allProducts?.description && (
              <p className="product-desc">
                {Utility.HTMLParser(allProducts?.description || "")}
              </p>
            )}
          </div>
          {/* key features */}
          {allProducts?.keyFeatures && (
            <div className="featuresContainer">
              <span className="features-title">Features</span>
              {Utility.HTMLParser(`${allProducts?.keyFeatures}`)}
            </div>
          )}
          {/* material info */}
          {allProducts?.materialInfo && (
            <div className="featuresContainer">
              <span className="features-title">Materials</span>
              <br />
              {Utility?.HTMLParser(allProducts?.materialInfo)}
            </div>
          )}

          {allProducts?.includedComponents && (
            <div
              className="d-flex colcontain align-items-center"
              style={{ fontFamily: "Roboto" }}
            >
              <span
                className="font-weight-bold mr-2"
                style={{ fontWeight: "bold" }}
              >
                Included Components:
              </span>
              <span>One (1) Dream Comfort Mattress</span>
            </div>
          )}
          {allProducts?.certifications && (
            <div className="d-flex colcontain align-items-center">
              <span
                className="font-weight-bold mr-2"
                style={{ fontWeight: "bold", fontFamily: "Roboto" }}
              >
                Certifications:
              </span>
              <span style={{ fontFamily: "Roboto" }}>
                {allProducts?.certifications}
              </span>
            </div>
          )}
        </Col>
        <Col sm={5} md={5} xs={12} className="productSection">
          <Col xs={12} className="maincol2">
            <p className="browse">Choose Color</p>
            <Row>
              <Col sm={12} xs={6}>
                <div className="circlecontainer">
                  {allProducts?.color &&
                    allProducts?.color?.length > 0 &&
                    allProducts?.color?.map((color, idx) => (
                      <div
                        className={`outer-color ${selectedColor === color ? "selected-color" : ""
                          }`}
                      >
                        <div
                          key={idx}
                          className="circle1 "
                          onClick={() => handleColorSelect(color)}
                          style={{
                            backgroundColor: color,
                          }}
                        ></div>
                        {/* {selectedColor === color && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "-5px",
                              left: "0",
                              right: "0",
                              height: "3px",
                              backgroundColor: "black",
                            }}
                          />
                        )} */}
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Available Size</p>
            <Row>
              <Col className="measure-dream  ">
                {allProducts?.size &&
                  allProducts?.size?.length > 0 &&
                  allProducts?.size?.map((size, idx) => (
                    <div
                      key={idx}
                      className={`size-box ${selectedSize === size ? "size-box-selected" : ""
                        }`}
                      onClick={() => handleSizeSelect(size)}
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      {size}
                    </div>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Select Fabric Patterns or Textures</p>
            <div className="pattern-img-container">
              {allProducts?.pattern && allProducts?.pattern?.length > 0 ? (
                allProducts?.pattern?.map((pattern, idx) => (
                  <div
                    className={`${selectedPattern === pattern ? "selected-pattern" : ""
                      }`}
                  >
                    {/* <div className="pattern-img-container"> */}
                    <img
                      onClick={() => handlePatternSelect(pattern)}
                      className="pattern-img"
                      // src="https://res.cloudinary.com/subhendu-spbp/image/upload/v1717507333/dwene9oovrjwfpjbjcha.png"
                      src={pattern}
                      alt=""
                    />
                    {/* </div> */}
                  </div>
                ))
              ) : (
                <p>No patterns available</p>
              )}
            </div>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Enter quantity</p>
            <input
              className="quantityField"
              type="number"
              // autoFocus={true}
              name="requiredQuantity"
              placeholder="1"
              onChange={handleQuantityChange}
              id=""
            ></input>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Provide Additional Information</p>
            <textarea
              // autoFocus={true}
              name="textareadream"
              placeholder=""
              onChange={handletextchange}
              id=""
            ></textarea>
          </Col>

          <Col>
            <p className="money">{Utility.MoneyFormatter(allProducts.price)}</p>
          </Col>
          <Col>
            <button onClick={handleOrderNow} className="dreambutton">
              Order Now
            </button>
          </Col>
        </Col>
      </Row>

    </Container>
  );
};

export default DreamComfirt;
