import img1 from "../../../Assets/Productimg/img1.png";
import img2 from "../../../Assets/Productimg/img2.png";

export const productdata = [
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img1,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
  {
    img: img2,
    title: "Solo Comfort Sofa",
    subtitile: "Unmatched Comfott for Restful Sleep",
  },
];
