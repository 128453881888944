import React from "react";
import "./MainFooter.css";
import { Container } from "react-bootstrap";

export const Footer = () => {
  return (
    <div className="footer">
      <p>©2024 MM Hometown Services Limited</p>
    </div>
  );
};
