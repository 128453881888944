import React from "react";
import RootRouter from "./Navigations/RootRouter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function App() {
  return (
    <div>
      <RootRouter />
      <ToastContainer />
    </div>
  );
}
