import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import UserHome from "../pages/UserHome/UserHome";
import { Footer } from "../Globalcomponents/footer/MainFooter";
import LandingPage from "../pages/Products/LandingPage";
import LuxuryHarmony from "../pages/Products/LuxuryHarmony";
import Dashboard from "../pages/Dashboard/Dashboard";
import MainNavbar from "../Globalcomponents/Navbar/MainNavbar";
import DreamComfirt from "../pages/Products/DreamComfirt";
import Login from "../pages/Login/Login";
import { Signup } from "../pages/Signup/Signup";
import ConfirmMessage from "../pages/Modals/ConfirmMessage/ConfirmMessage";
import CustomizedOrderModal from "../pages/Modals/CustomizedOrderModal/CustomizedOrderModal";

const Layout = ({ children }) => (
  <>
    <MainNavbar />
    <div style={{ paddingTop: "60px" }}>{children}</div>
    <Footer />
  </>
);

export default function RootRouter() {
  const { token } = useSelector((state) => state?.user);
  console.log(token, "toekn");
  return (
    <Router>
      <Routes>
        {token ? (
          <>
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/signup" element={<Navigate to="/" />} />
            <Route
              path="/"
              element={
                <Layout>
                  <UserHome />
                </Layout>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/product"
              element={
                <Layout>
                  <LandingPage />
                </Layout>
              }
            />
            <Route
              // path="/dreamcomfort"
              path="/product-details/:id"
              element={
                <Layout>
                  <DreamComfirt />
                </Layout>
              }
            />
            <Route
              path="/luxurycomfort"
              element={
                <Layout>
                  <LuxuryHarmony />
                </Layout>
              }
            />
            <Route path="/modal" element={<ConfirmMessage show={true} />} />
          </>
        ) : (
          <>
            <Route
              path="/"
              element={
                <Layout>
                  <UserHome />
                </Layout>
              }
            />
            <Route
              path="/product"
              element={
                <Layout>
                  <LandingPage />
                </Layout>
              }
            />
            <Route
              path="/product-details/:id"
              element={
                <Layout>
                  <DreamComfirt />
                </Layout>
              }
            />

            <Route
              path="/luxurycomfort"
              element={
                <Layout>
                  <LuxuryHarmony />
                </Layout>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />

            {/* Redirect to login if token is null */}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/dashboard" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
