import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import mmicon from "../../Assets/MMlogo/mmlogo.png.png";
import logo from "../../Assets/MMlogo/logo.png";
import CircularIndeterminate from "../../Globalcomponents/Spinner/Spinner";
import "./Login.css";
import ForgotPassword from "../Modals/ForgotPassword/ForgotPassword";
import Utility from "../../Utils/Utility";
import { useDispatch } from "react-redux";
import { login, setToken, setUserInfo } from "../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { UserController } from "../../Redux/controllers/UserController.js";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState("text");
  const [seePassword, setSeePassword] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [spin, setSpin] = useState(false);
  const [allinput, setInput] = useState({ email: "", password: "" });
  const [touched, setTouched] = useState(false);
  const [emailvalid, setEmailValid] = useState("");
  const [passwordvalid, setPasswordValid] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...allinput, [name]: value });

    if (touched) {
      if (name === "email" && value) {
        setEmailValid("");
      }
      if (name === "password" && value) {
        setPasswordValid("");
      }
    }
  };

  const handleForgotPassword = () => {
    setShowForgotModal(true);
  };

  const togglePassword = () => {
    setSeePassword(!seePassword);
    setType(type === "password" ? "text" : "password");
  };

  const handleNavigate = () => {
    navigate("/");
  };

  const handleLogin = async (event) => {
    setTouched(true);
    setSpin(true);
    event.preventDefault();
    let isValid = true;

    const data = {
      phoneNo: allinput.email,
      otp: allinput.password,
    };

    if (!allinput.email) {
      setEmailValid("Mobile number is required");
      isValid = false;
    } else {
      setEmailValid("");
    }

    if (!allinput.password) {
      setPasswordValid("Password is required");
      isValid = false;
    } else {
      setPasswordValid("");
    }

    if (isValid) {
      try {
        const result = await dispatch(login(data)).then(unwrapResult);
        console.log("login success========", result);
        setSpin(false);
        Utility.sToast("Logged in successfully");
        navigate("/dashboard");
      } catch (error) {
        setSpin(false);
      }
    } else {
      setSpin(false);
    }
  };

  const [mobileNo, setMobileNo] = useState("");

  const sendOtpToUser = async () => {
    try {
      if (!mobileNo) {
        Utility.eToast("Please Enter a valid mobile number");
        return;
      } else if (mobileNo.length !== 10) {
        Utility.eToast("Mobile Number should be 10 digits");
        return;
      }
      const res = await UserController.sendOtp({ phoneNo: mobileNo });
      setInput({ ...allinput, email: mobileNo });
      console.log("Resp", res);
      Utility.sToast(`Your OTP is  ${res.data.data}`);
    } catch (error) {
      console.log("Error In Send OTP", error);
    }
  };

  return (
    <Container className="loginContainer" fluid>
      {showForgotModal && (
        <ForgotPassword
          show={showForgotModal}
          onHide={() => {
            setShowForgotModal(false);
          }}
        />
      )}
      <div id="loginDiv">
        <div className="text-center">
          <img id="mmIcon" src={logo} alt="" />
        </div>
        <div className="text-center">
          <h1 id="loginHeading">User Login</h1>
        </div>
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label">Mobile Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Your Mobile Number"
              className="input"
              name="email"
              value={allinput.email}
              onChange={(e) => {
                setMobileNo(e.target.value);
                handleChange(e);
              }}
            />

            <span style={{ color: "red" }}>{emailvalid}</span>
            <p className="otpBox mt-lg-1" onClick={sendOtpToUser}>
              {" "}
              Send OTP
            </p>
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="label">OTP</Form.Label>
            <Form.Control
              type={"number"}
              placeholder="Enter OTP"
              className="input"
              name="password"
              value={allinput.password}
              onChange={handleChange}
            />
            {touched && allinput.password === "" ? (
              <span style={{ color: "red" }}>{passwordvalid}</span>
            ) : (
              ""
            )}
          </Form.Group>
          <Row className="d-flex justify-content-between">
            <Col className="d-grid">
              <Button size="lg" id="loginBtn" onClick={handleNavigate}>
                Back
              </Button>
            </Col>
            <Col className="d-grid">
              <Button type="submit" size="lg" id="loginBtn">
                {spin ? <CircularIndeterminate /> : "Log In"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default Login;
