import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { RxEyeOpen } from "react-icons/rx";
import { FaEyeSlash } from "react-icons/fa";
import { GoArrowLeft } from "react-icons/go";

import "./ForgotPassword.css";
const ForgotPassword = (props) => {
  const [type1, setType1] = useState("password");
  const [seePassword1, setSeePassword1] = useState(false);

  const [type2, setType2] = useState("password");
  const [seePassword2, setSeePassword2] = useState(false);

  const togglePassword1 = () => {
    setSeePassword1(!seePassword1);
    type1 === "password" ? setType1("text") : setType1("password");
  };
  const togglePassword2 = () => {
    setSeePassword2(!seePassword2);
    type2 === "password" ? setType2("text") : setType2("password");
  };

  const [showForgotModal, setShowForgotModal] = useState(false);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="contactModal"
    >
      {/* mx-auto d-flex justify-content-center align-items-center flex-column */}
      <Modal.Header closeButton className="forgotpassmodal">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="PassModalHeader text-center"
        >
          Forgot Password
        </Modal.Title>
        <div id="ModalHeadText">
          No Worries , We'll send the reset instructions
        </div>
      </Modal.Header>
      <Modal.Body className="w-100" id="passwordModalBody">
        <Form className="w-100" id="passwordModal">
          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="label">Password</Form.Label>
            <Form.Control
              type={type1}
              placeholder="Choose a secure password"
              className="forgotPswrdinput"
              pattern=".{6,}"
              title="Password must be at least 6 characters long"
              required
            />

            {seePassword1 ? (
              <FaEyeSlash id="eyeCloseIcon" onClick={togglePassword1} />
            ) : (
              <RxEyeOpen id="eyeOpenIcon" onClick={togglePassword1} />
            )}
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="label">Confirm Password</Form.Label>
            <Form.Control
              type={type2}
              placeholder="Re-enter your password for confirmation"
              className="forgotPswrdinput"
              pattern=".{6,}"
              title="Password must be at least 6 characters long"
              required
            />

            {seePassword2 ? (
              <FaEyeSlash id="eyeCloseIcon" onClick={togglePassword2} />
            ) : (
              <RxEyeOpen id="eyeOpenIcon" onClick={togglePassword2} />
            )}
          </Form.Group>
          <Form.Group>
            <Button size="lg" id="loginBtn1" type="submit">
              Reset Password
            </Button>
          </Form.Group>
          <Form.Group>
            <Button size="lg" id="loginBtn2" type="submit">
              <GoArrowLeft /> Back to Login
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPassword;
