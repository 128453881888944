import React from 'react'
import { FaArrowRight } from "react-icons/fa6";
import './ProductShop.css'

const ProductShopNow = () => {
    return (
        <button className='productbutton'>
            <p>Shop Now</p>
            <span className="arrow"><FaArrowRight id='arrowIcon'/></span>
        </button>
    )
}

export default ProductShopNow
