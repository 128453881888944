import React, { useState } from "react";
import "./Luxury.css";
import { Container, Row, Col } from "react-bootstrap";
import bed from "../../Assets/DreamComfortimg/bed.png";
import bedsheet from "../../Assets/DreamComfortimg/bedsheet.png";
import furniter from "../../Assets/DreamComfortimg/furniter.png";
import pillow from "../../Assets/DreamComfortimg/pillow.png";
import icon1 from "../../Assets/DreamComfortimg/icon1.png";
import icon2 from "../../Assets/DreamComfortimg/icon2.png";
import ProductShopNow from "../../Globalcomponents/ShopNowButton/ProductShopNow";
import icon3 from "../../Assets/DreamComfortimg/icon3.png";
import { useEffect } from "react";
import icon4 from "../../Assets/DreamComfortimg/icon4.png";
import Carousel from "react-bootstrap/Carousel";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import SwitchExample from "../../Globalcomponents/ToogleButton/ToogleButton";
// import DashboardModal from "../Components/DashboardModal/DashboardModal";
import CustomizedOrderModal from "../Modals/CustomizedOrderModal/CustomizedOrderModal";
import { productdata } from "./constants/ProductData";

const LuxuryHarmony = () => {

  const [showOrderModal, setShowOrderModal] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page smoothly
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const location = useLocation()
  const navigate = useNavigate()

  const handleOrderNow = () => {
    console.log("order now is clicked");
    setShowOrderModal(true);
  };

  const CustomSeparator = () => <span>&gt;</span>

  const handleHome = () => {
    navigate("/");
  };

  const handleProduct = () => {
    navigate("/product");
  };
  return (
    <Container className="topcontainer">
      {showOrderModal && (
        <CustomizedOrderModal
          show={showOrderModal}
          onHide={() => {
            setShowOrderModal(false);
          }}
        />
      )}

      <Breadcrumb className="dreambredcrumb">
        <Breadcrumb.Item
          active={location.pathname === "/"}
          onClick={handleHome}
        >
          Home
        </Breadcrumb.Item>
        <CustomSeparator />
        <Breadcrumb.Item
          active={location.pathname === "/product"}
          onClick={handleProduct}
        >
          Shop Products
        </Breadcrumb.Item>
        <CustomSeparator />
        <Breadcrumb.Item active>Products No - 2012</Breadcrumb.Item>
        <CustomSeparator />
        <Breadcrumb.Item active>Customize Your Products</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={7} className="maincol">
          <Row>
            <Col xs={12} className="d-none d-sm-block">
              {/* Render the regular image layout on larger screens */}
              <Row>
                <Col sm={4} className="dreamcol1">
                  <div className="dream">
                    <img src={bedsheet} alt="" />
                  </div>
                  <div className="dreamimg">
                    <img src={furniter} alt="" />
                  </div>
                  <div className="dreamimg">
                    <img src={pillow} alt="" />
                  </div>
                </Col>
                <Col sm={8} className="dreamcol2">
                  <div>
                    <img src={bed} alt="" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="dreamcarousel">
              <Carousel indicators={false}>
                <Carousel.Item className="carousel-item-fullscreen">
                  <div className="dream">
                    <img src={bed} alt="" className="dreamcarouselimg" />
                  </div>
                </Carousel.Item>
                <Carousel.Item className="carousel-item-fullscreen">
                  <div className="dreamimg">
                    <img src={bed} alt="" className="dreamcarouselimg" />
                  </div>
                </Carousel.Item>
                <Carousel.Item className="carousel-item-fullscreen">
                  <div className="dreamimg">
                    <img src={bed} alt="" className="dreamcarouselimg" />
                  </div>
                </Carousel.Item>
                <Carousel.Item className="carousel-item-fullscreen">
                  <div>
                    <img src={bed} alt="" />
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Col xs={12}>
            <div className="features-container">
              <span className="features-title">Features</span>
              <ul className="features-list">
                <li>Advanced Comfort Layers</li>
                <li>Breathable Fabric Cover</li>
                <li>Motion Isolation Technology</li>
                <li>Reinforced Edge Support</li>
                <li>Hypoallergenic Design</li>
              </ul>
            </div>
          </Col>
          <Col xs={12}>
            <div className="features-container">
              <span className="features-title">Materials</span>
              <ul className="features-list">
                <li>Comfort Layers: High-density memory foam</li>
                <li>Support Core: Responsive coil system</li>
                <li>Mattress Cover: Breathable fabric</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="d-flex luxurycolcontain align-items-center">
              <span className="font-weight-bold mr-2" style={{ fontWeight: "bold" }}> Included Components:</span>
              <span >One (1) Dream Comfort Mattress</span>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div className="d-flex luxurycolcontain align-items-center">
              <span className="font-weight-bold mr-2" style={{ fontWeight: "bold" }}>Certifications:</span>
              <span> CertiPUR-US® certified foam for safety and performance</span>
            </div>
          </Col>
        </Col>

        <Col md={5} sm={12} xs={12} className="maincol3">
          <Col>
            <p className="browse">Luxurious Harmony Bed</p>
            <p>
              Elevate your sleep experience with the Dream Comfort Mattress.
              Engineered with cutting-edge technology and premium materials,
              this mattress promises unparalleled comfort and rejuvenation for a
              restful night's sleep.
            </p>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Choose Color</p>
            <Row>
              <Col sm={6} xs={6}>
                <div className="circlecontainer">
                  <div class="circle1"></div>
                  <div className="ml-3">
                    <p>White</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="circlecontainer">
                  <div class="circle2"></div>
                  <div className="ml-3">
                    <p>Gray</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="circlecontainer">
                  <div class="circle3"></div>
                  <div className="ml-3">
                    <p>Beige</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="circlecontainer d-flex align-items-center">
                  <div class="circle4"></div>
                  <div className="ml-3">
                    <p>Navy Blue</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Choose Size</p>
            <Row>
              <Col className="measure">
                <p>Twin (99 cm x 190 cm)</p>
                <p>Twin (99 cm x 190 cm)</p>
                <p>Twin (99 cm x 190 cm)</p>
                <p>Twin (99 cm x 190 cm)</p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="maincol2">
            <p className="browse">Choose your foam type</p>
            <Col style={{ display: "flex", flexWrap: "wrap" }}>
              <Col sm={3} xs={6} style={{ textAlign: "center" }}>
                <img src={icon2} alt="" />
                <br />
                memory foam
              </Col>
              <Col sm={3} xs={6} style={{ textAlign: "center" }}>
                <img src={icon1} alt="" />
                <br />
                inspiring
              </Col>
              <Col sm={3} xs={6} style={{ textAlign: "center" }}>
                <img src={icon3} alt="" />
                <br />
                hybrid
              </Col>
              <Col sm={3} xs={6} style={{ textAlign: "center" }}>
                <img src={icon3} alt="" />
                <br />
                hybrid
              </Col>


            </Col>
          </Col>
          <Col xs={12} className="maincol2">
            <Col
              sm={12}
              xs={12}
              style={{
                width: "100%",
                borderRadius: "10px",
                marginTop: "1vh",
                padding: 0
              }}
            >
              <div className="circlecontainer d-flex align-items-center ">
                <div>Include storage features like drawers or shelves</div>
                <div className="ml-3" style={{ marginTop: "-3px" }}>
                  <SwitchExample />
                </div>
              </div>
            </Col>
          </Col>

          <Col>
            <p className="money">Rs.6990</p>
          </Col>
          <Col>
            <button onClick={handleOrderNow} className="dreambutton">Order Now</button>
          </Col>
        </Col>
      </Row>
      <Row>
        <div class="line"></div>
      </Row>
      <Row>
        <div className="mt-3 mb-3">
          <h2
            className="first-heading text-center"
            style={{
              color: "#642315",
              fontWeight: "500",
            }}
          >
            You May Like Also
          </h2>
        </div>
      </Row>
      <Row>
        {productdata.slice(0, 4).map((item) => (
          <Col xs={6} sm={3} key={item.id}>
            <Card>
              <Card.Img variant="top" src={item.img} />
              <Card.Body>
                <Card.Text>
                  <p className="cardtitle">{item.title}</p>
                  <p>{item.subtitile}</p>
                </Card.Text>

                <ProductShopNow />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LuxuryHarmony;
