import React, { useState } from "react";
import mmicon from "../../Assets/MMlogo/mmlogo.png.png";
import { Container, Form, Button } from "react-bootstrap";
import { RxEyeOpen } from "react-icons/rx";
import { FaEyeSlash } from "react-icons/fa";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import Utility from "../../Utils/Utility";
import { useDispatch } from "react-redux";
import { UserController } from "../../Redux/controllers/UserController";

export const Signup = () => {
  const [seePassword2, setSeePassword2] = useState(false);
  const [type2, setType2] = useState("password");
  const [type1, setType1] = useState("password");
  const [seePassword1, setSeePassword1] = useState(false);
  const [emailvalid, setemailvalid] = useState("");
  const [namevalid, setNamevalid] = useState("");
  const [adressvalid, setadressvalid] = useState("");
  const [Phonevalid, setPhonevalid] = useState("");
  const [Conformpasswordvalid, setConformpassword] = useState("");
  const [passwordvalid, setpasswordvalid] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allinput, setAllinput] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    phoneNo: "",
    conformPassword: "",
  });

  const handleChange = (e) => {
    setAllinput({ ...allinput, [e.target.name]: e.target.value });
  };

  const data = {
    role: "user",
    name: allinput.name,
    email: allinput.email,
    password: allinput.password,
    address: allinput.address,
    phoneNo: allinput.phoneNo,
  };

  const handleSubmit = async () => {
    let isvalid = true;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    // const phoneregex = /^[1-9]\d{2}-\d{3}-\d{4}$/;

    if (!allinput.email) {
      setemailvalid("Email is required");
      isvalid = false;
    } else if (!regex.test(allinput.email)) {
      setemailvalid("Please enter a valid email");
      isvalid = false;
    } else {
      setemailvalid("");
    }

    if (!allinput.phoneNo) {
      setPhonevalid("Phone number is required");
      isvalid = false;
    }
    // else if (!phoneregex.test(allinput.phoneNo)) {
    //   setPhonevalid("Please enter a valid phone number");
    //   isvalid = false;
    // }
    else {
      setPhonevalid("");
    }

    if (allinput.password !== allinput.conformPassword) {
      setConformpassword("Password does not match");
      isvalid = false;
    } else {
      setConformpassword("");
    }

    if (!allinput.password) {
      setpasswordvalid("Password is required");
      isvalid = false;
    } else {
      setpasswordvalid("");
    }

    if (!allinput.name) {
      setNamevalid("Name is required");
      isvalid = false;
    } else {
      setNamevalid("");
    }

    if (!allinput.address) {
      setadressvalid("Address is required");
      isvalid = false;
    } else {
      setadressvalid("");
    }

    if (isvalid) {
      try {
        const response = await UserController.RegisterUser(data);
        Utility.sToast("User Register Successfully");
        navigate("/login");
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  const togglePassword2 = () => {
    setSeePassword2(!seePassword2);
    type2 === "password" ? setType2("text") : setType2("password");
  };

  const togglePassword1 = () => {
    setSeePassword1(!seePassword1);
    type1 === "password" ? setType1("text") : setType1("password");
  };

  return (
    <Container className="signupContainer d-flex justify-content-center align-items-center">
      <div id="signupDiv">
        <div className="iconDiv text-center">
          <img src={mmicon} alt="" />
        </div>
        <div className="text-center">
          <h1 id="singupHeading">Welcome to MM Hometown</h1>
        </div>
        <div className="text-center">
          <p id="signupMsg">
            Ready to join our community? Sign up now and unlock a world of
            possibilities.
          </p>
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label">Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Name"
              className="input"
              name="name"
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{namevalid}</span>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label">Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder=" Provide a valid email address"
              className="input"
              name="email"
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{emailvalid}</span>
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="label">Password</Form.Label>
            <Form.Control
              type={type1}
              placeholder="Choose a secure password"
              className="input"
              title="Password must be at least 6 characters long"
              name="password"
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{passwordvalid}</span>
            {seePassword1 ? (
              <FaEyeSlash id="eyeCloseIcon" onClick={togglePassword1} />
            ) : (
              <RxEyeOpen id="eyeOpenIcon" onClick={togglePassword1} />
            )}
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="label">Confirm Password</Form.Label>
            <Form.Control
              type={type2}
              placeholder="Re-enter your password for confirmation"
              className="input"
              name="conformPassword"
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{Conformpasswordvalid}</span>
            {seePassword2 ? (
              <FaEyeSlash id="eyeCloseIcon" onClick={togglePassword2} />
            ) : (
              <RxEyeOpen id="eyeOpenIcon" onClick={togglePassword2} />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label">Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Address"
              className="input"
              name="address"
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{adressvalid}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label className="label">Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Phone Number"
              className="input"
              name="phoneNo"
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{Phonevalid}</span>
          </Form.Group>

          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          ></Form.Group>
          <Form.Group>
            <Button size="lg" id="signupBtn" onClick={handleSubmit}>
              Sign Up
            </Button>
          </Form.Group>
        </Form>
        <div className="text-center mt-3">
          <p id="homelogin">
            Already have an account? <span>Log In</span>
          </p>
        </div>
      </div>
    </Container>
  );
};
