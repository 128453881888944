import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Dashboard.css";
import { FaRegCircleCheck, FaPlay } from "react-icons/fa6";
import DashboardModal from "../Modals/DashboardModal/DashboardModal";
import { useDispatch, useSelector } from "react-redux";
import { UserController } from "../../Redux/controllers/UserController";
import { MdPending } from "react-icons/md";
import { GrInProgress } from "react-icons/gr";
import { FaShippingFast } from "react-icons/fa";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import Utility from "../../Utils/Utility";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../Redux/Slice/userSlice";
import CircularIndeterminate from "../../Globalcomponents/Spinner/Spinner";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { token, userInfo } = useSelector((state) => state?.user);
  const [allProducts, setAllProducts] = useState([]);
  const userId = useSelector((state) => state.user.userInfo._id);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await UserController.getSpecificUserById(token, userId);
      setAllProducts(res?.data?.data);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Here is the user ID", userId);
    getAllProducts();
  }, [userId]);

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const statusIcons = {
    pending: <MdPending style={{ color: "red" }} />,
    confirmed: <FaRegCircleCheck style={{ color: "green" }} />,
    processing: <GrInProgress style={{ color: "purple" }} />,
    shipped: <FaShippingFast style={{ color: "teal" }} />,
    delivered: <IoCheckmarkDoneCircleSharp style={{ color: "green" }} />,
  };
  // console.log("All Product", allProducts);
  return (
    <Container
      className={`heading-text ${window.innerWidth < 576 ? "mt-1" : "mt-5"}`}
    >
      {showModal && (
        <DashboardModal
          product={selectedProduct}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}

      {/* First row */}
      <Row className="rw">
        <Col className={`content p-3 ${window.innerWidth < 576 ? "mb-3" : ""}`}>
          <div className="heading">
            <h6 className="dashSubHeading">Personal Information</h6>
          </div>
          <p className="personalInfo">
            Name: <span>{userInfo?.name}</span>
          </p>
          <p className="personalInfo">
            Phone Number: <span>{userInfo?.phoneNo}</span>
          </p>
          <p className="personalInfo">
            Email: <span>{userInfo?.email}</span>
          </p>
          <p className="personalInfo">
            Address: <span>{userInfo?.address}</span>
          </p>
        </Col>
      </Row>

      {/* Second row */}
      <Row className="rw">
        <h4 className="mb-3 mt-0 dashHeading">Orders History</h4>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <CircularIndeterminate />
          </div>
        ) : (
          <>
            {" "}
            {allProducts.length > 0 ? (
              allProducts.map((item, index) => (
                <div key={index} className="content mb-4 p-3">
                  <Row>
                    <Col
                      sm={9}
                      className={`${window.innerWidth < 576 ? "mb-3" : ""}`}
                    >
                      <Row className="align-items-center justify-content-between">
                        <Col
                          className={`${window.innerWidth < 576 ? "mb-4" : ""}`}
                        >
                          <img
                            src={
                              item?.productDetails[0]?.product?.images[0] ||
                              item?.productDetails[0]?.product?.images[1] ||
                              item?.productDetails[0]?.product?.images[2] ||
                              item?.productDetails[0]?.product?.images[3]
                            }
                            className="dashboardImg"
                            alt="N/A"
                          />
                        </Col>
                        <Col>
                          <h6 className="dashSubHeading">
                            {item?.approverDetails?.name}
                          </h6>
                          <div>
                            <p className="dashPara">
                              {item.customerDetails.shippingAddress}
                            </p>
                          </div>
                          <div>
                            <p className="dashPara">
                              Total Amount:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {Utility.MoneyFormatter(
                                  item.paymentDetails.totalAmount
                                )}
                              </span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={3} className="statusPosition">
                      <div
                        className="detailIcon"
                        onClick={() => handleShowModal(item)}
                      >
                        <FaPlay />
                      </div>
                      <div className="deliveryDetailsStatus">
                        <div>
                          {statusIcons[item.deliveryStatus]}{" "}
                          {item.deliveryStatus}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <p className="noOrders">No products order yet.</p>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
