import axios from "axios";

const API_BASE_URL = "https://jbsapi.mailstone.co.in/api/v1/";
// const API_BASE_URL = "https://jbs-backend-9oni.onrender.com/api/v1/";
// const API_BASE_URL = "http://192.168.29.29:8080/api/v1/";
// const API_BASE_URL = "http://192.168.29.92:8080/api/v1/";
// const API_BASE_URL = "http://192.168.254.212:8080/api/v1/";
// const API_BASE_URL = "http://localhost:8080/api/v1/";
const apiCaller = (
  uri,
  method = "GET",
  data = {},
  token,
  contentType = null
) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API_BASE_URL + uri,
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const deleteApicaller = (uri, token) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: API_BASE_URL + uri,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const HttpClient = { apiCaller, API_BASE_URL, deleteApicaller };
