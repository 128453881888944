import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import logo from "../../Assets/MMlogo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../Redux/Slice/userSlice";
import Utility from "../../Utils/Utility";
import "./MainNavbar.css";
import LogoutModal from "../../pages/Modals/LogoutModal/LogoutModal"

function MainNavbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.token);
  const [expanded, setExpanded] = useState(false);

  const handleLogin = () => {
    if (token) {
      Utility.sToast("Already LoggedIn");
    } else {
      navigate("/login");
    }
  };
  const [modal2, setModal2] = useState(false);
  const handelSubmit = () => {
    setModal2(true);
  }

  const handleLogout = () => {
    dispatch(logOut());
    Utility.sToast("Logout Successfully");
  };

  const handleConfirmLogout = (value) => {
    if (value === "ok") {
      handleLogout();
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      className="bg-body-tertiary mainnav"
    >
      <Container className="navbarbg" fluid>
        <Navbar.Brand className="left">
          <div className="LogoIconAtNavBar">
            <img src={logo} alt="" className="navbarimage" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleToggle}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="custom-collapse">
          <div className="me-auto mmtext">
            <p>Jbs Interior</p>
          </div>
          <div className="me-auto middle">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "navbarcontaint active" : "navbarcontaint"
              }
              onClick={handleNavLinkClick}
            >
              Home
            </NavLink>
            <NavLink
              to="/product"
              className={({ isActive }) =>
                isActive ? "navbarcontaint active" : "navbarcontaint"
              }
              onClick={handleNavLinkClick}
            >
              Product
            </NavLink>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? "navbarcontaint active" : "navbarcontaint"
              }
              onClick={handleNavLinkClick}
            >
              Dashboard
            </NavLink>
          </div>
          <div className="d-flex ">
            {token ? (
              <div
                className="logout me-3"
                onClick={() => {
                  handelSubmit();
                  handleNavLinkClick();
                }}
              >
                <span>Logout</span>
              </div>
            ) : (
              <div
                className="logout me-3"
                onClick={() => {
                  handleLogin();
                  handleNavLinkClick();
                }}
              >
                <span>LogIn</span>
              </div>
            )}
            <div
              className="mainnavicon"
            // onClick={handleLogin}
            >
              <FaUser style={{ fontSize: "1.6rem", color: "black" }} />
            </div>
          </div>
        </Navbar.Collapse>

        {
          modal2 && (
            <LogoutModal
              show={modal2}
              onHide={() => {
                setModal2(false);
              }}
              onConfirm={handleConfirmLogout}
            />
          )
        }
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
