import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import Col from "react-bootstrap";
import { Form, Container, Col, Row, Spinner } from "react-bootstrap";
import "./CustomizedOrderModal.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { UserController } from "../../../Redux/controllers/UserController";
import axios from "axios";
import CircularIndeterminate from "../../../Globalcomponents/Spinner/Spinner";
import { useSelector } from "react-redux";
import CreatOrUpdateUser, {
  createOrUpdateUser,
} from "../../../Redux/Slice/userSlice";
import { useDispatch } from "react-redux";

import Utility from "../../../Utils/Utility";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

function CustomizedOrderModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state?.user?.token);
  const userid = useSelector((state) => state?.user?.userInfo?._id);
  const userinfo = useSelector((state) => state?.user?.userInfo);

  const [namevalid, setNamevalid] = useState("");
  const [emailvalid, setemailvalid] = useState("");
  const [phonevalid, setphonevalid] = useState("");
  const [shipingvalid, setshipingvalid] = useState("");
  const [totalamontvalid, settotalamontvalid] = useState("");
  const [otpvalid, setOtpvalid] = useState("");
  const [Approvervalid, setApprovervalid] = useState("");
  const [Employeevalid, setEmployeevalid] = useState("");
  const [EmployeeNumbervalid, setEmployeeNumbervalid] = useState("");
  const [paymentMethodValid, setPaymentMethodValid] = useState("");
  const [ApproverOtpvalid, setApproverOtpvalid] = useState("");
  const [Datevalid, setDatevalid] = useState("");
  const [spin, setSpin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessOrderModal, setShowSuccessOrderModal] = useState(false);
  const [relevantFileName, setRelevantFileName] = useState("");
  const [paymentFileName, setPaymentFileName] = useState("");
  const [touched, seTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmpVerified, setIsEmpVerified] = useState(false);
  const [OtpData, setOtpData] = useState({
    phoneNo: userinfo?.phoneNo || "",
    name: userinfo?.name || "",
    email: userinfo?.email || "",
    otp: "",
  });
  // const [estimatedDeliveryDate,setEstimatedDeliveryDate]=useState("")

  const [CustomeDetails, setCustomerDetails] = useState({
    customer: userid,
    shippingAddress: userinfo?.address || "",
    orderNumber: "",
    relevantDoc: "",
  });

  useEffect(() => {
    if (userid) {
      setCustomerDetails((prevDetails) => ({
        ...prevDetails,
        customer: userid,
      }));
    }
  }, [userid]);

  const [PaymentDetails, setPaymentDetails] = useState({
    totalAmount:
      props.alldata?.productDetails?.requirements?.productPrice *
      props.alldata?.productDetails?.requirements?.requiredQuantity,
    advanceAmount: "",
    remainingAmount:
      props.alldata?.productDetails?.requirements?.productPrice *
      props.alldata?.productDetails?.requirements?.requiredQuantity,
    paymentMethod: "",
    paymentDoc: "",
    additionalComments: "",
  });

  const [approvedDetails, setApprovedDetails] = useState({
    name: "",
    employeeID: "",
    mobileNo: "",
    estimatedDeliveryDate: "",
    otp: "",
  });

  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const handleApprovedChange = (e) => {
    setApprovedDetails({ ...approvedDetails, [e.target.name]: e.target.value });
    console.log(approvedDetails);
  };

  const handleChange = (e) => {
    setOtpData({ ...OtpData, [e.target.name]: e.target.value });
    // console.log(OtpData);
  };

  const secondHandleChange = (e) => {
    setCustomerDetails({ ...CustomeDetails, [e.target.name]: e.target.value });
    console.log(CustomeDetails);
  };

  const handlepaymentchange = (e) => {
    const updatedPaymentDetails = {
      ...PaymentDetails,
      [e.target.name]: e.target.value,
    };
    const remainingAmount =
      updatedPaymentDetails.totalAmount - updatedPaymentDetails.advanceAmount;

    updatedPaymentDetails.remainingAmount =
      remainingAmount < 0 ? "0" : remainingAmount.toString();

    setPaymentDetails(updatedPaymentDetails);
  };
  const handleFile = () => {
    fileInputRef.current.click();
  };

  let Customer_Id;
  let CustomerDetailsImage;

  const handleFileChange = async (e) => {
    const filedata = e.target.files[0];
    if (filedata) {
      setRelevantFileName(filedata?.name);
    }
    try {
      const Formdata = new FormData();
      Formdata.append("doc", filedata);
      const response = await UserController.singleDocUpload(Formdata);
      CustomerDetailsImage = response.data.doc;
      setCustomerDetails((prevDetails) => ({
        ...prevDetails,
        relevantDoc: CustomerDetailsImage,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const VarifyOtp = async () => {
    console.log("here  is the sended data =====================", OtpData);
    try {
      dispatch(createOrUpdateUser(OtpData)).then(unwrapResult);
    } catch (error) {
      console.log("Error while sending otp", error);
    }
  };
  let ProductDetailsImage;
  const handlePaymentdocument = async (e) => {
    const filedata = e.target.files[0];
    if (filedata) {
      setPaymentFileName(filedata?.name);
    }
    try {
      const Formdata = new FormData();
      Formdata.append("doc", filedata);
      const response = await UserController.singleDocUpload(Formdata);
      ProductDetailsImage = response.data.doc;
      setPaymentDetails((prevDetails) => ({
        ...prevDetails,
        paymentDoc: ProductDetailsImage,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const retirneddata = props.alldata;
  let productDetails = retirneddata.productDetails;
  const finalDetails = {
    productDetails,
    customerDetails: {
      customer: userid,
      shippingAddress: CustomeDetails.shippingAddress,
      orderNumber: CustomeDetails.orderNumber,
      relevantDoc: CustomeDetails.relevantDoc,
    },
    paymentDetails: {
      totalAmount: PaymentDetails.totalAmount,
      advanceAmount: PaymentDetails.advanceAmount || 0,
      remainingAmount: PaymentDetails.remainingAmount || 0,
      paymentMethod: PaymentDetails.paymentMethod,
      paymentDoc: PaymentDetails.paymentDoc,
      additionalComments: PaymentDetails.additionalComments,
    },
    approverDetails: {
      name: approvedDetails.name,
      employeeID: approvedDetails.employeeID,
      mobileNo: approvedDetails.mobileNo,
    },
    estimatedDeliveryDate: approvedDetails.estimatedDeliveryDate,
  };

  const Togglemodal = () => {
    setShowModal(!showModal);
  };

  const handleFormSubmit = async (e) => {
    seTouched(true);
    e.preventDefault();
    let isvalid = true;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!OtpData.name) {
      setNamevalid("Name is required");
      isvalid = false;
      // return;
    } else {
      setNamevalid("");
      isvalid = true;
    }
    if (!OtpData.email) {
      setemailvalid("Email is required");
      isvalid = false;
      // return;
    } else if (!regex.test(OtpData.email)) {
      setemailvalid("Please enter a valid email");
      isvalid = false;
    } else {
      setemailvalid("");
      isvalid = true;
    }
    if (!OtpData.phoneNo) {
      setphonevalid("Phone number is required");
      isvalid = false;
      // return;
    } else if (OtpData.phoneNo.length !== 10) {
      setphonevalid("Phone Number should be 10 digits");
      isvalid = false;
      // return;
    } else {
      setphonevalid("");
      isvalid = true;
    }
    if (!OtpData.otp) {
      setOtpvalid("Otp is required");
      isvalid = false;
      // return;
    } else {
      setOtpvalid("");
      isvalid = true;
    }
    if (!isEmpVerified) {
      Utility.eToast("Please verify OTP");
      isvalid = false;
      // return;
    }

    if (!CustomeDetails.shippingAddress) {
      setshipingvalid("Shipping address is required");
      isvalid = false;
      // return;
    } else {
      setshipingvalid("");
      isvalid = true;
    }

    if (!PaymentDetails.totalAmount) {
      settotalamontvalid("Enter total amount");
      isvalid = false;
    } else {
      settotalamontvalid("");
      isvalid = true;
    }
    if (!PaymentDetails.paymentMethod) {
      setPaymentMethodValid("Provide payment method");
      isvalid = false;
    } else {
      setPaymentMethodValid("");
      isvalid = true;
    }
    if (!approvedDetails.name) {
      setApprovervalid("Approver Name is required");
      isvalid = false;
    } else {
      setApprovervalid("");
      isvalid = true;
    }
    if (!approvedDetails.employeeID) {
      setEmployeevalid("Employee id is required");
      isvalid = false;
    } else {
      setEmployeevalid("");
      isvalid = true;
    }
    if (!approvedDetails.mobileNo) {
      setEmployeeNumbervalid("Mobile Number is required");
      isvalid = false;
    } else {
      setEmployeeNumbervalid("");
      isvalid = true;
    }

    if (!approvedDetails.estimatedDeliveryDate) {
      setDatevalid("Date is required");
      isvalid = false;
    } else {
      setDatevalid("");
      isvalid = true;
    }

    if (!approvedDetails.otp) {
      setApproverOtpvalid("Otp is required");
      isvalid = false;
    } else {
      setApproverOtpvalid("");
      isvalid = true;
    }

    if (isvalid) {
      setSpin(true);
      try {
        const finaldata = JSON.stringify(finalDetails);
        console.log("Final Data", finaldata);

        const response = await UserController.OrderSubmit(finaldata);
        console.log("Response", response);
        // props.onHide();
        setSpin(false);
        setShowSuccessOrderModal(true);
      } catch (error) {
        console.log("Error", error);
        // props.onHide();
        setSpin(false);
        Utility.eToast("Something went wrong while ordering");
      }
    }
  };

  const getEmployeeDetails = async () => {
    if (!approvedDetails?.employeeID) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await UserController.getEmployeeDetails({
        empID: approvedDetails?.employeeID,
      });
      setApprovedDetails({
        ...approvedDetails,
        name: data?.data?.name,
        mobileNo: data?.data?.phoneNo,
      });
      console.log(data);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  const sendOtp = async (phoneNo) => {
    if (!phoneNo) {
      return;
    }
    try {
      const { data } = await UserController.sendOtp({
        phoneNo,
      });
      Utility.sToast(data?.data);
      console.log(data);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    }
  };
  const verifyEmployee = async () => {
    if (!approvedDetails?.mobileNo) {
      return Utility.eToast("please enter employee id");
    }
    if (!approvedDetails?.otp) {
      return Utility.eToast("please enter otp");
    }
    try {
      const { data } = await UserController.verifyEmployee({
        phoneNo: approvedDetails?.mobileNo,
        otp: approvedDetails?.otp,
      });
      Utility.sToast("employee verified successfully");
      setIsEmpVerified(true);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="orderModal"
    >
      {showSuccessOrderModal ? (
        <>
          <Modal.Header
            closeButton
            className="modalheaderm"
            style={{ backgroundColor: "#F4F4F2", color: "black" }}
          >
            <Modal.Title>
              Congratulations! Your Submission Was Successful!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "white", color: "black" }}>
            <Container>
              <Row
                className="cutomizedrow"
                style={{ backgroundColor: "#F4F4F2" }}
              >
                <Col className="mainmodalcol">
                  <div style={{ color: "black" }}>
                    <p className="modalparagraph">
                      Thank you for submitting your information. Your details
                      have been
                    </p>
                    <p className="modalparagraph">
                      successfully received, and we'll process your request
                      shortly.
                    </p>
                    <br />
                    <p className="modalparagraphhh">
                      Should you have any questions or require further
                      assistance,
                    </p>
                    <p className="modalparagraph">
                      please don't hesitate to contact us. We're here to help!
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton style={{ background: "#F4F4F2" }}>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ color: "black" }}
              className="dashboardModalHeading"
            >
              Provide your details to confirm your customized order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="mx-auto d-flex justify-content-center  align-items-center flex-column"
            id="backGround"
          >
            <Container>
              <Form className="w-100">
                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    className="custom-placeholder"
                    placeholder="Enter your Name"
                    name="name"
                    value={OtpData?.name}
                    disabled={token}
                    onChange={(event) => {
                      setNamevalid("");
                      handleChange(event);
                    }}
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{namevalid}</span>
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    className="custom-placeholder"
                    placeholder="Enter your Email address"
                    name="email"
                    value={OtpData?.email}
                    disabled={token}
                    onChange={(event) => {
                      handleChange(event);
                      setemailvalid("");
                    }}
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{emailvalid}</span>
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    type="number"
                    className="custom-placeholder"
                    placeholder="Enter your Phone Number "
                    name="phoneNo"
                    value={OtpData?.phoneNo}
                    disabled={token}
                    onChange={(event) => {
                      handleChange(event);
                      setphonevalid("");
                    }}
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{phonevalid}</span>
                </Form.Group>
                {token ? (
                  ""
                ) : (
                  <Col className="sendotp">
                    <p
                      className="otp"
                      onClick={() => sendOtp(OtpData?.phoneNo)}
                    >
                      Send OTP
                    </p>
                  </Col>
                )}

                {!token && (
                  <Form.Group className="mb-3 mt-3">
                    <Form.Control
                      className="custom-placeholder"
                      placeholder="Enter 6 digit code sent to Your Mobile Number "
                      name="otp"
                      onChange={(event) => {
                        handleChange(event);
                        setOtpvalid("");
                      }}
                      style={{ backgroundColor: "#F4F4F2", color: "black" }}
                    ></Form.Control>
                    <span style={{ color: "red" }}>{otpvalid}</span>
                  </Form.Group>
                )}

                {token ? (
                  ""
                ) : (
                  <Col className="sendotp">
                    {/* <p className="resendotp">Resend OTP</p> */}
                    <p
                      className="resendotp"
                      onClick={VarifyOtp}
                      style={{ color: "black" }}
                    >
                      Verify OTP
                    </p>
                  </Col>
                )}
                {/* <Col className="sendotp">
              <p className="resendotp">Varify OTP</p>
            </Col> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    className="custom-placeholder"
                    as="textarea"
                    name="shippingAddress"
                    onChange={(event) => {
                      secondHandleChange(event);
                      setshipingvalid("");
                    }}
                    rows={5}
                    placeholder="Shipping Address"
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  />
                </Form.Group>
                <span style={{ color: "red" }}>{shipingvalid}</span>
                {/* <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    className="custom-placeholder"
                    placeholder="Order Number (if available)"
                    name="orderNumber"
                    onChange={secondHandleChange}
                  ></Form.Control>
                </Form.Group> */}

                <Form.Group className="mb-3 mt-3">
                  <div
                    className="position-relative p-2"
                    style={{
                      background: "#F4F4f2",
                      color: "black",
                      border: "1px solid #642315e5",
                      borderRadius: "4px",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {relevantFileName && relevantFileName !== ""
                        ? relevantFileName.length > 45
                          ? relevantFileName.slice(0, 45) + "...."
                          : relevantFileName
                        : "Upload Any Relevant Files (e.g., design mockups)"}
                    </span>
                    <span
                      className="position-absolute"
                      style={{ right: "12px" }}
                      onClick={handleFile}
                    >
                      <MdOutlineFileUpload />
                    </span>

                    <input
                      type="file"
                      accept="image/*"
                      className="position-absolute"
                      id="fileUpload"
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="file" placeholder="name@example.com" />
            </Form.Group> */}

                <Form.Group>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Payment Details
                  </p>
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    type="number"
                    className="custom-placeholder"
                    placeholder="Total Amount"
                    name="totalAmount"
                    onChange={(event) => {
                      handlepaymentchange(event);
                      settotalamontvalid("");
                    }}
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                    value={PaymentDetails?.totalAmount}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{totalamontvalid}</span>
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                  <div className="d-flex justify-content-between w-100">
                    <Form.Group style={{ width: "45%" }}>
                      <Form.Control
                        type="number"
                        className="custom-placeholder"
                        placeholder="Advance Payment (if any)"
                        name="advanceAmount"
                        onChange={handlepaymentchange}
                        style={{ backgroundColor: "#F4F4F2", color: "black" }}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      style={{ width: "45%" }}
                      className="position-relative"
                    >
                      <Form.Control
                        type="number"
                        className="estimated-date-container"
                        name="remainingAmount"
                        disabled
                        // onChange={handlepaymentchange}
                        value={PaymentDetails?.remainingAmount}
                        style={{ backgroundColor: "#F4F4F2", color: "black" }}
                      ></Form.Control>
                      {/* <span className="estimatedDate">
                        {PaymentDetails.remainingAmount !== ""
                          ? PaymentDetails.remainingAmount
                          : "Amount Due"}
                      </span> */}
                    </Form.Group>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Select
                    aria-label="Default select example"
                    className="custom-placeholder"
                    name="paymentMethod"
                    onChange={(event) => {
                      handlepaymentchange(event);
                      setPaymentMethodValid("");
                    }}
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  >
                    <option>Payment Method</option>
                    <option value="cash">Cash</option>
                    <option value="check">Check</option>
                    <option value="bank_transfer_or_upi">
                      Bank Transfer/upi
                    </option>
                  </Form.Select>
                  <span style={{ color: "red" }}>{paymentMethodValid}</span>
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                  <div
                    className="position-relative p-2"
                    style={{
                      background: "#F4F4F2",
                      border: "1px solid #642315e5",
                      borderRadius: "4px",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {paymentFileName && paymentFileName !== ""
                        ? paymentFileName.length > 45
                          ? paymentFileName.slice(0, 45) + "...."
                          : paymentFileName
                        : "Upload Payment document (if any)"}
                    </span>
                    <span
                      className="position-absolute"
                      style={{ right: "12px" }}
                      onClick={handleFile}
                    >
                      <MdOutlineFileUpload />
                    </span>

                    <input
                      type="file"
                      accept="image/*"
                      className="position-absolute"
                      id="fileUpload"
                      onChange={(e) => handlePaymentdocument(e)}
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    className="custom-placeholder"
                    as="textarea"
                    rows={5}
                    placeholder="Additional comments or instructions"
                    name="additionalComments"
                    onChange={handlepaymentchange}
                    style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  />
                </Form.Group>
              </Form>

              <Form.Group>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Employe Details
                </p>
              </Form.Group>
              <Form.Group className="mb-3 mt-3 position-relative">
                <Form.Control
                  className="custom-placeholder"
                  placeholder="Employee ID"
                  name="employeeID"
                  onChange={(event) => {
                    handleApprovedChange(event);
                    setEmployeevalid("");
                  }}
                  style={{ backgroundColor: "#F4F4F2", color: "black" }}
                ></Form.Control>

                <Button
                  className="get-details-btn"
                  onClick={getEmployeeDetails}
                  style={{
                    backgroundColor: "#dc2329",
                    color: "#fff",
                    border: "none",
                  }}
                >
                  get details
                </Button>
                <span style={{ color: "red" }}>{Employeevalid}</span>
              </Form.Group>
              <Form.Group className="mb-3 mt-3">
                <Form.Control
                  className="custom-placeholder"
                  placeholder="Employee Name"
                  name="name"
                  value={approvedDetails?.name}
                  onChange={(event) => {
                    handleApprovedChange(event);
                    setApprovervalid("");
                  }}
                  style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  disabled
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
                <Form.Control
                  className="custom-placeholder"
                  placeholder="Employee Mobile number "
                  name="mobileNo"
                  value={approvedDetails?.mobileNo}
                  onChange={(event) => {
                    handleApprovedChange(event);
                    setEmployeeNumbervalid("");
                  }}
                  style={{ backgroundColor: "#F4F4F2", color: "black" }}
                  disabled
                ></Form.Control>
              </Form.Group>
              {!isEmpVerified && (
                <>
                  <Col className="sendotp">
                    <p
                      className="otp"
                      onClick={() => sendOtp(approvedDetails?.mobileNo)}
                    >
                      Send OTP
                    </p>
                  </Col>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Control
                      className="custom-placeholder"
                      placeholder="Enter 6 digit code sent to Your Mobile Number "
                      name="otp"
                      onChange={(event) => {
                        handleApprovedChange(event);
                        setApproverOtpvalid("");
                      }}
                      style={{ backgroundColor: "#F4F4F2", color: "black" }}
                    ></Form.Control>
                    <span style={{ color: "red" }}>{ApproverOtpvalid}</span>
                  </Form.Group>
                  <Col className="sendotp">
                    {/* <p className="resendotp">Resend OTP</p> */}
                    <p className="resendotp" onClick={verifyEmployee}>
                      Verify OTP
                    </p>
                  </Col>
                </>
              )}
              <Form.Group className="mb-3 mt-3 position-relative">
                <Form.Control
                  className="estimated-date-container"
                  placeholder=" "
                  type="date"
                  name="estimatedDeliveryDate"
                  onChange={(event) => {
                    handleApprovedChange(event);
                    setDatevalid("");
                  }}
                  style={{ backgroundColor: "#F4F4F2", color: "black" }}
                ></Form.Control>
                <span
                  className="estimatedDate"
                  style={{ backgroundColor: "#F4F4F2", color: "black" }}
                >
                  {approvedDetails?.estimatedDeliveryDate &&
                  approvedDetails?.estimatedDeliveryDate !== ""
                    ? approvedDetails?.estimatedDeliveryDate
                    : " Estimated Date of Delivery"}{" "}
                </span>
                <span style={{ color: "red" }}>{Datevalid}</span>
              </Form.Group>
              <div className="mainmodalbutton">
                <Button
                  size="lg"
                  variant="secondary"
                  className="lastbuttonmodal"
                  onClick={handleFormSubmit}
                  style={{
                    backgroundColor: "#dc2329",
                    color: "#fff",
                    border: "none",
                  }}
                >
                  {spin ? <CircularIndeterminate /> : "Submit"}
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default CustomizedOrderModal;
