import React, { useEffect, useState } from "react";
import "./UserHome.css";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import f1 from "../../Assets/UserHome/Frame 10.jpg";
import f2 from "../../Assets/UserHome/Frame 11.jpg";
import f3 from "../../Assets/UserHome/Frame 12.jpg";
import f4 from "../../Assets/UserHome/Rectangle 4.png";
import f5 from "../../Assets/UserHome/Rectangle 5.png";
import f6 from "../../Assets/UserHome/Rectangle 6.png";
import bed from "../../Assets/UserHome/bed.jpg";
import sofa from "../../Assets/UserHome/sofa.png";
import kitchen from "../../Assets/UserHome/kitchen.png";
import interior from "../../Assets/UserHome/interior.png";
import flower from "../../Assets/UserHome/flower.png";
import woodenChair from "../../Assets/UserHome/woodenChair.jpg";
import furniture from "../../Assets/UserHome/furniture.png";
import fabrics from "../../Assets/UserHome/fabrics.png";
import messageIcon from "../../Assets/UserHome/messageIcon1.png";
import { FaLongArrowAltRight } from "react-icons/fa";
import ContactusModal from "../Modals/ContactusModal/ContactusModal";
import { TbMessageMinus } from "react-icons/tb";
import { useSelector } from "react-redux";
// import CustomizedOrderModal from "../Components/CustomizedOrderModal/CustomizedOrderModal";
// import DashboardModal from "../Components/DashboardModal/DashboardModal";
// import ConfirmMessage from "../Components/ConfirmMessage/ConfirmMessage";

export default function UserHome() {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page smoothly
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [showContactModal, setShowContactModal] = useState(false);
  const [cards, setCards] = useState([
    { image: sofa, title: "Sofas & Couches" },
    { image: bed, title: "Beds & Mattresses" },
    { image: kitchen, title: "Kitchen & Appliances" },
    { image: interior, title: "Interior Essentials" },
  ]);
  const [products, setProducts] = useState([
    {
      image: flower,
      title: "Explore Our Premium Wood Selections",
      bg: "#C99F62",
      color: "#642315",
    },
    {
      image: fabrics,
      title: "Explore Our Range of High-Quality Fabrics",
      bg: "#642315",
      color: "#C99F62",
    },
    {
      image: woodenChair,
      title: "Discover Our Range of Finishes",
      bg: "#C99F62",
      color: "#642315",
    },
    {
      image: furniture,
      title: "Explore Our Range of Furniture Styles",
      bg: "#642315",
      color: "#C99F62",
    },
  ]);

  const handleContactUs = () => {
    setShowContactModal(true);
  };

  const handleShopNow = () => {
    navigate("/product");
  };
  return (
    <Container className={`home-container`}>
      {/* MODAL  */}
      {showContactModal && (
        <ContactusModal
          show={showContactModal}
          onHide={() => {
            setShowContactModal(false);
          }}
        />
      )}
      <div id="msgBtn" onClick={handleContactUs}>
        <TbMessageMinus className="MessageIconContainer" />
      </div>
      {/* <div id="msgBtn" onClick={handleContactUs}>
        <img id="msgIcon" src={messageIcon} alt=""></img>
      </div> */}

      {/* FIRST ROW  */}
      <Row className="first-row mb-5">
        <Col sm={6} className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
          <Container
            className={`home-heading d-flex flex-column justify-content-between w-100 h-100 rounded-3 ${
              window.innerWidth < 576 ? "mb-3" : ""
            }`}
          >
            <div id="row-heading">
              <h1 className="fw-bold">Elevating Homes, One Piece at a Time.</h1>
            </div>
            <div id="divShopNow">
              <button
                id="shopNow"
                className="rounded-5 home-shop-now"
                onClick={handleShopNow}
              >
                Shop Now <FaLongArrowAltRight />
              </button>
            </div>
          </Container>
        </Col>

        <Col sm={3} className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
          <img className="w-100" alt="" src={f1} />
        </Col>
        <Col sm={3} className="d-flex justify-content-between flex-column">
          <Row>
            <div className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
              <img className="w-100 h-100" alt="" src={f2} />
            </div>
          </Row>
          <Row>
            <div className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
              <img className="w-100 h-100" alt="" src={f3} />
            </div>
          </Row>
        </Col>
      </Row>

      {/* second row  */}
      <Row className="second-row mb-5">
        <div className="mt-3 mb-3">
          <h2 className="first-heading text-center">Shop By Category</h2>
        </div>

        {cards.map((element, index) => {
          return (
            <Col
              key={index}
              sm={3}
              className={`${window.innerWidth < 576 ? "mb-3" : ""}`}
            >
              <div id="cardContainer">
                <img
                  src={element.image}
                  alt=""
                  className="w-100 h-100"
                  id="cardImg"
                />
                <div id="bottomDivContainer">
                  <div id="cardButton" onClick={handleShopNow}>
                    {element.title}
                  </div>
                </div>
                <div id="bottomDiv" />
              </div>
            </Col>
          );
        })}
      </Row>

      {/* third row */}
      <Row className="third-row mb-5">
        <div className="mt-3 mb-3 text-center">
          <h2 className="first-heading">Customize Your Furniture</h2>
        </div>
        <Row>
          {products.map((element, index) => {
            return (
              <Col
                sm={3}
                key={index}
                className={`${window.innerWidth < 576 ? "mb-3" : ""}`}
              >
                <Card
                  style={{
                    width: "100%",
                    background: element.bg,
                    color: element.color,
                    zIndex: 0,
                  }}
                  id="productCard"
                >
                  <Card.Img
                    style={{ height: "220px", width: "100%" }}
                    variant="top"
                    src={element.image}
                  />
                  <Card.Body className="p-3" id="cardBody">
                    <Card.Title style={{ color: "#FEFEFE" }}>
                      {element.title}
                    </Card.Title>
                    <div
                      className="text-end"
                      onClick={handleShopNow}
                      style={{
                        padding: "0rem",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          background: "#dc2329",
                          padding: "0rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "23px",
                          width: "50px",
                        }}
                      >
                        <FaLongArrowAltRight color="white" />
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Row>

      {/* fourth row  */}
      <Row className="second-row mb-5">
        <div className="mt-3 mb-3">
          <h2 className="first-heading text-center">Start - Customize Now</h2>
        </div>
        <Col sm={3} className="d-flex justify-content-between flex-column">
          <Row>
            <div className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
              <img className="w-100 h-100" alt="" src={f5} />
            </div>
          </Row>
          <Row>
            <div className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
              <img className="w-100 h-100" alt="" src={f6} />
            </div>
          </Row>
        </Col>

        <Col sm={3} className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
          <img className="w-100" src={f4} alt="" />
        </Col>

        <Col sm={6} className={`${window.innerWidth < 576 ? "mb-3" : ""}`}>
          <Container
            className={`d-flex home-heading w-100 h-100 rounded-3 ${
              window.innerWidth < 576 ? "mb-3" : ""
            }`}
          >
            <div className="mt-5">
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1 className="fw-bold" style={{ alignSelf: "center" }}>
                  Custom Furniture Design
                </h1>
              </div>
              <div className="text">
                <h5 className="text-light">
                  Furniture in your own style and size
                </h5>
              </div>
              <div className="text-para">
                <p>
                  We are a furniture manufacturer with large capacity to design
                  and manufacture wooden furniture on order.
                </p>
              </div>
              <div className="text">
                <p className="text-light">Lets talk over phone or WhatsAPP</p>
              </div>
              <div className="text-center">
                <button
                  className="rounded-5 contactBtn"
                  onClick={handleContactUs}
                >
                  +91 147852369
                </button>
              </div>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
